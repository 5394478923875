/*
 * https://stackoverflow.com/questions/27706594/how-can-i-make-recaptcha-a-required-field
 */

define([], function () {
  class Captcha {
    init(element) {
      const self = this;
      this.element = element;
      const recaptcha = element.querySelector('#g-recaptcha-response');
      if (recaptcha) {
        recaptcha.setAttribute('required', 'required');
      }
      
      // Prevent the user from tabbing to this element
      const recaptchaResponse = document.getElementById('g-recaptcha-response');
      recaptchaResponse.setAttribute('aria-hidden', 'true');
      recaptchaResponse.setAttribute('tabindex', '-1');
      
      recaptchaResponse.addEventListener('focus', () => {
        self.getNextFocusableElement(recaptchaResponse).focus();
      });
    }
    
    getNextFocusableElement(el) {
      const focusableEls = document.querySelectorAll('a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
      const list = Array.prototype.slice.call(focusableEls).filter(el => el.offsetParent); // Remove hidden elements
      const index = list.indexOf(el);
      return list[index + 1] || list[0];
    };
  }
  
  return Captcha;
});
